import React, { Component } from "react"
import { Helmet } from "react-helmet"
import CvOutcomes from '../components/1.0_cv_outcomes/CvOutcomes'
import Layout from '../components/Layout'
import ISI from '../components/isi/ISI'
import Footer from '../components/footer/Footer'
import SEO from "../components/seo"

import Loader from "../components/loader"


const OutcomesRefs = (props) => {
   return (
      <p className="cv-footnote"><strong>Reference: 1. </strong>Bhatt DL, Steg PG, Miller M, et al; on behalf of the REDUCE-IT Investigators. Effects of icosapent ethyl on total ischemic events: from REDUCE-IT. <em>J Am Coll Cardiol.</em> 2019;73(22):2791-2802.</p>
    )
}

export default class CVOutcomes extends Component {

  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
      isMobile: false,
      modal:  null
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
    this.setState({ isMobile: bool })
  }

  handleClick(e) {
    // console.log('clicked');
  }

  goModal = (evt) => {
    // let str = evt.target.dataset.modal;
    this.setState(() => ({ modal: evt }))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null }))
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {

    return (
      <>
      <Loader>
      <Layout>
        <SEO
          title="CV Outcomes for FDA-Approved Therapy"
          keywords={[""]}
          description="Discover additional information on groundbreaking results from the landmark REDUCE-IT® trial with VASCEPA, an FDA-approved medication for reducing CV risk. Read the study and learn about VASCEPA safety."/>
        <CvOutcomes isMobile={this.state.isMobile}/>
      </Layout>
      <ISI disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
      <div className="ref-outer">
      <div className="footnotes-container">
        <div className='references'>
            <OutcomesRefs />
        </div>
      </div>
      </div>
      <Footer />
      </Loader>
      </>

    )
  }
}
