import React from "react"
import { Link } from "gatsby"

import './cv_outcomes.css'

import icon_01 from '../../images/CVOutcomes_icon-1.png'
import icon_02 from '../../images/CVOutcomes_icon-2.png'
import icon_03 from '../../images/CVOutcomes_icon-3.png'
import bck from "../../images/landingpage_images/1.0_landingpage_image.jpg";

export default (props) => {

  return (
    <section className="landing-wrapper grey-bg">
      <h1 style={{display: "none" }}>Outcomes data: Groundbreaking results from REDUCE-IT®</h1>
      
        <div className="landing-grid-container three-column landing-grid-container-top-margin">
            <article className="landing-content">
              <img className="landing-icon" src={icon_01} alt="Graph icon"/>
              <h2 className="landing-subhead">Outcomes<br/>data</h2>
              <p className="landing-copy">Groundbreaking results from REDUCE-IT<sup className="sup-align-top">&reg;</sup> </p>
              <Link className="homeBtn" to="/outcomes-data/">See the data</Link>
            </article>

            {props.isMobile ? <hr/> : null}

            <article className="landing-content">
              <img className="landing-icon" src={icon_02} alt="Check icon"/>
              <h2 className="landing-subhead">Safety & side<br/>effects</h2>
              <p className="landing-copy">VASCEPA has a well-established <span>safety profile</span></p>
              <Link className="homeBtn" to="/safety/">Explore</Link>
            </article>

            {props.isMobile ? <hr/> : null}

            <article className="landing-content">
              <img className="landing-icon" src={icon_03} alt="Down percent icon"/>
              <h2 className="landing-subhead">Total events<br/>analysis</h2>
              <p className="landing-copy">Persistent CV Risk (P-CVR) reduction beyond the first event in a <strong><em>prespecified</em> and exploratory</strong> analysis<sup>1</sup></p>
              <Link className="homeBtn" to="/total-events-analysis/">Learn more</Link>
            </article>
        </div>
    </section>


  )
}
